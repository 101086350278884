import React from 'react'
import styled from 'styled-components'
import {useMediaPredicate} from 'react-media-hook'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import Chip from '@material-ui/core/Chip'
import {ButtonBase} from '@material-ui/core'
import useUpload from 'hooks/useUpload'
import Card from './CollectionCard'
import CardSkeleton from 'components/common/CardSkeleton'

TimeAgo.addLocale(en)

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 30px;
  justify-content: left;
  @media (max-width: 523px) {
    justify-content: center;
  }
  
`

const StyledChip = styled(Chip)`
  position: absolute;
  right: -95px; 
  text-align: center; 
  background-color: #c8cdd2 !important; 
  color: #FFF !important; 
  font-weight: bold;
`

const AddCard = styled.div`
  width: 200px;
  height: 250px;
  border-radius: 5px;
  margin-left: 11px;
  margin-bottom: 22px;  
  background-color: #5173E7;
  color: #FFF;
  :hover {
    background-color: #6284E8;
    transform: scale(1.03);
    transition: background-color .5s, transform .7s;
    div {
      transform: scale(1.2);
      transition: transform 1s;
    }
  }
  transition: background-color .5s, transform .7s;
  .MuiButtonBase-root {
    padding: 0 20px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    h2 {
      text-align: left;
    }
    div {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      font-size: 5em;
      transition: transform 1s;
    }
  }
  
  
  
`

const TimeTab = ({i, currCollection, prevCollection}) => {
  // responsive
  const showMonthTabWidths = [
    useMediaPredicate('(min-width: 1587px)')
  ]
  const showMonthTab = showMonthTabWidths.some(m => m === true)
  // calc if new month, show tab
  const started = new Date(currCollection.started * 1000)
  let month = started.getMonth() + 1
  let year = started.getFullYear()
  // comapred to previous
  const prevStarted = new Date((prevCollection || {}).started * 1000)
  let prevMonth = prevStarted.getMonth() + 1
  let pevYear = prevStarted.getFullYear()
  // has changed
  const changed = month !== prevMonth || year !== pevYear
  // not IE compatible
  try {
    month = month.toString().padStart(2, '0')
  } catch (err) {
    console.log('not allowed tab padding')
  }

  return (i === 0 || changed) && showMonthTab && <StyledChip label={month + '-' + year} />
}

const CollectionListCard = (props) => {
  const {collections, usage, className, style, loading, isSharedCollections, searchTerm} = props
  const {openDialog} = useUpload()

  const availablePlaces = [
    useMediaPredicate('(min-width: 0px)'),
    useMediaPredicate('(min-width: 461px)'),
    useMediaPredicate('(min-width: 902px)'),
    useMediaPredicate('(min-width: 1155px)'),
    useMediaPredicate('(min-width: 1382px)')
  ]
  
  const noCards = collections.length === 0
  const showAddCard = !isSharedCollections && (!loading || (loading && !noCards)) && !searchTerm
  // accounting for 1 place for the "add card" card
  const extraAddCard = showAddCard ? 1 : 0
  const places = availablePlaces.filter(place => place).length  || 0
  const cardCount = collections.length + extraAddCard
  const overSplill = cardCount % places
  const toFill = places - overSplill
  const fillerCards = collections.length > 0 && (places === 1 || overSplill === 0) ? '' : [...new Array(noCards ? places - extraAddCard : toFill)].map((a, i) => <CardSkeleton key={i} />)

  return (
    <Root className={className} style={style} >
      {
        showAddCard && (
          <AddCard className='createButton-sc'>
            <ButtonBase onClick={() => openDialog({view: 'file-drop', responsive: true})}> 
              <h4>Upload and transcribe</h4>
              <div>+</div>
            </ButtonBase>
          </AddCard>
        )
      }
      
      {
        collections.map((collection, i) => (
          <div
            key={collection.id}
            draggable
            onDragStart={(ev) => ev.dataTransfer.setData('collection', JSON.stringify({name: collection.name, id: collection.id}))}>
            <TimeTab i={i} currCollection={collection} prevCollection={collections[Math.abs(i - 1)]} />
            <Card usage={usage} id={collection.id} />
          </div>
        ))
      }
      {fillerCards}
    </Root>

  )
}

export default CollectionListCard
