import React, {useEffect} from 'react'
import {useMediaPredicate} from 'react-media-hook'

import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Slide from '@material-ui/core/Slide'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'

import useProfile from 'hooks/useProfile'

const StyledModal = styled(Modal)`
  background-color: rgba(0,0,0,0.1);
  display: flex;
  align-items: center;
  border: none;
  justify-content: center;
  outline: none;
  .MuiBackdrop-root  {
    background-color: rgb(241 241 241 / 40%);
  }
`

const Card = styled.div`
  background-color: #FFF; 
  padding: 40px 30px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0px 0 6px rgba(0,0,0,.3);
  outline: none;
  min-height: 285px;
  min-width: 600px;
  @media (max-width: 600px) {
    min-width: 390px;
  }
  p {
    width: 340px;
    text-align: center;
    line-height: 1.5em;
    color: #777;
    margin: 20px 0;
    padding: 0;
  }
  img {
    width: 400px;
    @media (max-width: 500px) {
      width: 280px;
    }
    @media (max-height: 620px) {
      width: 270px;
    }
    @media (max-height: 518px) {
      display: none;
    }    
    border: 2px solid #cacaca;
    border-radius: 6px;
    box-shadow: 0 0 13px rgb(0 0 0 / 10%);
  }
  div.short {
    @media (max-height: 518px) {
      height: 80px;
    }
  }
`

const Header = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 30px;
  font-size: 1.2em;
  color: #333;
  text-align: center;
  position: relative;
  color: #FFF;
`
const Options = styled.div`
  display: flex;
  align-items: center;
  border: none;
  justify-content: center;
  flex-direction: column;
  .MuiButton-root {
    min-width 200px;
    padding: 10px 20px;
    font-weight: bold;
  }
`

const ImageCont = styled.div`  
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  
`

const Background = styled.div`
  position: absolute;
  height: 150px;
  width: 100%;
  background-color: #2091d0;
  left: 0;
  overflow: hidden;
  top: 10px;
  z-index: 0;
`

export const Welcome = () => {
  const {showWelcome, profile} = useProfile()
  const show = profile?.settings?.showWelcome
  const closeUpload = () => showWelcome(false)
  return (
    <StyledModal
      open={!!show}
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      onClose={closeUpload}
      closeAfterTransition
      BackdropComponent={Backdrop}
    >
      <Slide direction="down" in={show} mountOnEnter unmountOnExit>
        <Card>
          <Background className='short' />
          <Background className='short' style={{top: 0, borderRadius: '8px'}} />
          <Header>Getting Started</Header>
          <ImageCont>
            <img src='https://uploads-ssl.webflow.com/5f8a27fa913a100a39ea46f9/61062c776dcf0efa4ccad0cd_dashboard_welcome_animation_600.gif' alt='Welcome Animation' />
            <p>
                You can upload your audio and video files one at a time, 
                or dragging many at once. 
                Hover over the blue, round, upload button for more options.
            </p>
          </ImageCont>
          <Options><Button onClick={closeUpload}>Got it. Thanks!</Button></Options>
        </Card>
      </Slide>
    </StyledModal>
  )
}
export default ({profile: {settings, isLoaded, organization}, updateSettings}) => {
  const moveModal = useMediaPredicate('(max-width: 1120px)')
  const showHelper = useMediaPredicate('(min-width: 950px)')
  const [openUpload, setOpenUpload] = React.useState(false)
  const [openHeaderMenu, setOpenHeaderMenu] = React.useState(false)
  
  const closeUpload = () => {
    setOpenUpload(false)
    updateSettings({'help.upload': new Date().getTime()})
  }

  const closeMenuHeader = () => {
    setOpenHeaderMenu(false)
    updateSettings({'help.headerMenu': new Date().getTime()})
  }

  console.log({organization})

  useEffect(() => {
    if (isLoaded && showHelper) { 
      if (!settings?.help?.upload) {
        setOpenUpload(true)
      } else if (!settings?.help?.headerMenu && organization)  {
        
        setTimeout(() => setOpenHeaderMenu(true), 1500)
      }
    }
  }, [isLoaded, settings?.help?.upload, organization])

  return (
    <>
      <Welcome openUpload={openUpload} closeUpload={closeUpload} />

      <StyledModal
        open={openHeaderMenu}
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        onClose={closeMenuHeader}
        closeAfterTransition
        BackdropComponent={Backdrop}
      >
        <Slide direction="up" in={openHeaderMenu} mountOnEnter unmountOnExit>
          <Card style={{minHeight: 'initial', minWidth: 'initial', marginRight: moveModal ? '350px' : 0, marginTop: '-50px'}}>
            <Background style={{height: '90px'}} />
            <Background style={{top: 0, height: '90px', borderRadius: '8px'}} />
            <Header>User Menu and Options</Header>
            <ImageCont>
              <p style={{paddingTop: '20px'}}>
              Use the menu to upgrade your plans, or configure your Workspace. 
              Once you have more than more Workspace, use the menu to quickly switch between them.
              </p>
            </ImageCont>
            <Options><Button onClick={closeMenuHeader}>Got it. Thanks!</Button></Options>
          </Card>
        </Slide>
      </StyledModal>
    </>
  )
}
