import React, {Fragment} from 'react'
import styled from 'styled-components'
import {useDropzone} from 'react-dropzone'
import Button from 'components/common/Button'
import FileIcon from 'components/common/CreatorDialog/FileIcon'
import Media from 'react-media'
import {toast} from 'components/common/Toaster'

const SubmitButton = styled(Button)`
  margin-left: 6px;
  ${props => props.disabled === true && 'opacity: 0;display: none;'}
`

const UploaderCont = styled.div`
  width: 70%;
  height: 70%;
  margin: auto;

  @media (max-height: 300px) { 
    height: 50%;
  }
`

const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 8%;
  left: 0;
  right: 0;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.3px;
  white-space: nowrap;
`

const Underlined = styled.span`
  color: #FC600F;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
`

const RecordIcon = styled.span`
  color: #FC600F;
  font-size: 14px;
  margin-right: 6px;
`
const FileIconImg = styled(FileIcon)`  
  @media (max-height: 300px) { 
    svg {
      height: 40px;
    }
  }
`

const Uploader = styled.div`
  width: 100%;
  height: 100%;
  border: 2px dashed #FFF;
  background: rgba(0,0,0,0.5);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${({reject}) => reject && 'background: rgba(255, 0,0,0.5);'}
  :hover {
    background: rgba(0,0,0,0.7);
  }
`

const UploaderMessage = styled.div`
  margin-top: 44px;
  font-size: 24px;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media (max-height: 300px) { 
    margin-top: 8px;
    font-size: 17px;
  }
`

const UploadMessage = (props) => {
  const {isDragActive, isDragReject} = props //, isDragAccept, acceptedFiles, fileRejections
  // console.log({isDragActive, isDragReject, isDragAccept, acceptedFiles, fileRejections})
  if (isDragReject === true) {
    return <span>File type not unsupprted.</span>
  } else if (isDragActive) return <span>Drop it here</span>

  return (
    <Fragment>
      <Media query={{minWidth: 500}}><span>Drag your media file here to transcribe or &nbsp;</span></Media>
      <SubmitButton raised primary>Browse</SubmitButton>
    </Fragment>
  )
}

const FileUploader = (props) => {
  // const reader = new FileReader()
  const {onUpload, showRecorder} = props
  const dropProps = useDropzone({
    accept: 'video/*, audio/*',
    onDrop: (files) => {
      console.log({files})
      const errorFiles = []
      const filteredFiles = files.filter(file => {
        if (file.name.includes('.aif')) {
          errorFiles.push('AIF')
        } else return file
      })

      if (errorFiles.length > 0) {
        toast.error(
          <div>
            <span
              tabIndex={0}
              role="button" 
              styling="text"
              onKeyDown={(evt) => evt.keyCode === 13 && toast.dismissAll() && window.Beacon('article', '5c33f84f2c7d3a31944fc438')}
              onClick={() => {
                toast.dismissAll()
                window.Beacon('article', '5c33f84f2c7d3a31944fc438')
              }}
            >
              {errorFiles.join(',')} files are not supported. Read more about supported files <i><u>here</u></i>.
            </span>
          </div>
        )
      }
      // console.log(filteredFiles)
      onUpload(filteredFiles)
    }
  })
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = dropProps

  // console.log(dropProps)
  // draggedFiles.forEach((file) => {
  //   reader.onabort = () => console.log('file reading was aborted')
  //   reader.onerror = () => console.log('file reading has failed')
  //   reader.onload = () => {
  //   // Do whatever you want with the file contents
  //     const binaryStr = reader.result
  //     console.log(binaryStr)
  //   }
  //   reader.readAsArrayBuffer(file)
  //  })
  // console.log('dropProps', dropProps)
  const removeIcon = isDragActive || isDragReject
  return (
    <UploaderCont>

      <Uploader {...getRootProps({className: 'dropzone'})} reject={isDragReject === true} >
        <input {...getInputProps()} />
        {!removeIcon && <FileIconImg />}
        <UploaderMessage >
          <UploadMessage isDragActive={isDragActive} isDragReject={isDragReject} isDragAccept={isDragAccept} acceptedFiles={acceptedFiles} fileRejections={fileRejections} />
        </UploaderMessage>
      </Uploader>

      <Message onClick={showRecorder}>
        <RecordIcon className='material-icons'>lens</RecordIcon>Or&nbsp;<Underlined>capture</Underlined>&nbsp;a video on your webcam
      </Message>
    </UploaderCont>
  )
}

export default FileUploader
