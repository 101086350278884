import React, {useState, useEffect} from 'react'
import FadeIn from 'react-fade-in'
import styled from 'styled-components'
import * as Sentry from '@sentry/react'

import TextField from '@material-ui/core/TextField'

import {Card, Heading, ContentWrapper, SubHeading} from '../Settings/StyledComponent'
import useProfile from 'hooks/useProfile'
import Button from 'components/common/Button'
import Spinner from 'components/common/Spinner'

const PageWrapper = styled.div`
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgb(236, 240, 244,1);
  display: flex;
  align-items: start;
  justify-content: center;
  padding-top: 10vh;
`

const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default () => {
  const {uid, oid, organizations, profile, updateEmail} = useProfile() // syncProfileOrganizations
  const {isLoaded, email} = profile
  const [name, setName] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [error, setError] = useState()
  const [processing, setProcessing] = useState(false)

  const update = async evt => {
    setProcessing(true)
    const {data, error} = await updateEmail(name)
    console.log(data)
    if (error === 'already-exists') {
      setError('Email already exists')
      setIsValid(false)
      document.querySelector('.update-email input').select()
    }
    setProcessing(false)
  }

  useEffect(() => {
    if (isLoaded && !profile.email && profile.providerId) {
      console.log('we need an email')
      Sentry.captureMessage(`User using SAML was missing email in DB ${uid}`)
    }
  }, [isLoaded, organizations.length])

  useEffect(() => {
    setError()
    setIsValid(emailRegEx.test(String(name).toLowerCase()))
  }, [name])
  
  if (!isLoaded || (isLoaded && oid && email)) return ''
  // if (!itsBeenAMinuteSinceJoining) return ''
  
  if (profile.email) return ''
  return (
    <PageWrapper>
      <FadeIn>
        <ContentWrapper className='create-email'>
          <Heading>Please provide your email</Heading>
          <SubHeading>We didn't catch your email on registration.</SubHeading>
          <Card style={{minHeight: 'initial'}}>
            <form  
              noValidate 
              autoComplete='off' 
              onSubmit={evt => {
                update(evt)
                evt.preventDefault()
              }}>
              <p/>
              <TextField 
                className='update-email'
                fullWidth
                error={!!error}
                helperText={error ? 'Email has already been taken. Please try another.' : 'For example, name@email.com'}
                label="Your email" 
                placeholder="Type your email here..."  
                variant="outlined" 
                onChange={evt => setName(evt.target.value)} 
              />
              <div style={{margin: '20px 0',display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                <Button   
                  type='button' 
                  disabled={!name || processing || !isValid} 
                  onClick={update} 
                  raised 
                  primary
                  style={{    
                    marginLeft: '12px',
                  }}
                >
                  {
                    processing ?
                      <Spinner small color='#FFF' /> :
                      'Update Email'
                  }
                </Button>
              </div>
            </form>
          </Card>
        </ContentWrapper>
      </FadeIn>
    </PageWrapper>
  )
}
